<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="utilityForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="utilityForm.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Service Code"
            rules="required"
          >
            <b-form-group
              label="Service Code"
              label-for="service_code"
            >
              <b-form-input
                id="service_code"
                v-model="utilityForm.service_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Service Provider Code"
            rules="required"
          >
            <b-form-group
              label="Service Provider Code"
              label-for="service_provider_code"
            >
              <b-form-input
                id="service_provider_code"
                v-model="utilityForm.service_provider_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Provider Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Short Code"
            rules="required"
          >
            <b-form-group
              label="Short Code"
              label-for="short_code"
            >
              <b-form-input
                id="short_code"
                v-model="utilityForm.short_code"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Short Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="type"
          >
            <b-form-group
              label="Choose Type"
              label-for="type"
            >
              <v-select
                v-model="utilityForm.type"
                label="name"
                :options="types"
                :filterable="false"
                :searchable="false"
                :reduce="types => types.name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="serviceTransactionTypes"
          >
            <b-form-group
              label="Choose Service Transaction Type"
              label-for="serviceTransactionTypes"
            >
              <v-select
                v-model="utilityForm.service_transaction_type"
                label="name"
                :options="serviceTransactionTypes"
                :filterable="false"
                :searchable="false"
                :reduce="serviceTransactionTypes => serviceTransactionTypes.name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUtility()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUtility()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      categories: [],
      countryId: '',
      loader: false,
      errorMsg: '',
      errors: {},
      types: [
        {
          name: 'Water',
        },
        {
          name: 'Gas',
        },
        {
          name: 'Electricity Bills',
        },
      ],
      serviceTransactionTypes: [
        {
          name: 'Payment',
        },
        {
          name: 'inquiry',
        },

      ],

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const utilityForm = ref({

    })

    return {
      getValidationState,
      utilityForm,
    }
  },
  mounted() {
    this.showUtility()
  },
  methods: {

    showUtility() {
      if (this.$route.params.id) {
        axios.get(`utilities/${this.$route.params.id}`).then(res => {
          this.utilityForm = res.data.data
        })
      } else {
        return false
      }
      return true
    },

    addUtility() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.utilityForm) {
          formData.append(key, this.utilityForm[key])
        }

        formData.append('_method', 'PUT')

        axios.post(`utilities/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'utilities' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.utilityForm) {
          formData.append(key, this.utilityForm[key])
        }

        this.loader = true
        axios.post('utilities', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'utilities' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  </style>
